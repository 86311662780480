<template>
  <v-sheet class="pa-5 pb-10" elevation="1">
    <h1 class="display-1">Calculate Fare</h1>
    <v-divider class="mt-3"></v-divider>
    <div class="mt-5">
      <v-row>
        <v-col cols="12" md="6">
          <v-text-field
            label="Distance"
            v-model="distance"
            dense
            hide-details
            outlined
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            label="Seat"
            v-model="seat"
            dense
            hide-details
            outlined
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            label="Luggage"
            v-model="luggage"
            dense
            hide-details
            outlined
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-btn color="primary" @click="viewFare">Test</v-btn>
        </v-col>
      </v-row>
    </div>

    <v-divider class="mt-3"></v-divider>
    <div class="mt-5">
      <h2>Planning</h2>
      <v-calendar
        ref="calendar"
        v-model="focus"
        color="primary"
        :events="events"
        :type="type"
      >
        <template v-slot:event="{ event }">
          <div
            class="calendar-event fill-height fill-width d-flex align-center justify-center black--text"
          >
            {{ event.name }}
          </div>
        </template>
      </v-calendar>
    </div>
  </v-sheet>
</template>

<script>

import * as dayjs from 'dayjs'
var advancedFormat = require('dayjs/plugin/advancedFormat')
dayjs.extend(advancedFormat)

import * as fb from '../../firebase'

export default {

  data() {
    return {
      focus: '',
      type: 'week',
      events: [
        {
          name: 'TPC - DB',
          start: '2022-04-27 10:00',
          end: '2022-04-27 10:30',
          color: 'pink lighten-3'
        },
        {
          name: 'TR-Pre-V',
          start: '2022-04-27 10:30',
          end: '2022-04-27 11:00',
          color: 'yellow lighten-1'
        },
        {
          name: 'Meeting',
          start: '2022-04-27 11:00',
          end: '2022-04-27 12:30',
          color: 'green accent-3'
        },
        {
          name: 'TR-Post. V',
          start: '2022-04-27 12:30',
          end: '2022-04-27 13:00',
          color: 'yellow lighten-1'
        },
        {
          name: 'TRe - VB',
          start: '2022-04-27 13:00',
          end: '2022-04-27 13:30',
          color: 'blue-grey lighten-4'
        }
      ],

      distance: 0,
      luggage: 0,
      seat: 0,
      parameters: {
        basicFlatRate: 50,
        pricePerKm: 20,
        seatPercent: 11,
        luggagePercent: 8
      }
    }
  },

  mounted () {
      this.$refs.calendar.checkChange()
    },

  methods: {

    async testFb() {
      try {
        
        const tmp = await fb.db.collection('tmpCol').add({ name: 'Year' })

        console.log(tmp.id)

      } catch (error) {
        console.log(error)
      }
    },

    addEvent() {

      let tripDateTime = 1651122300
      let estimatedTime = '23 mins'
      let garageToSource = {
        distance: '15 km',
        time: 29,
        rest: 25
      }
      let destinationToGarage = {
        distance: '9.0 km',
        time: 19,
        rest: 25
      }

      // Main trip

      let mainTripStartTime = dayjs.unix(tripDateTime).format('YYYY-MM-DD HH:mm')
      let mainTripEndTime = dayjs(mainTripStartTime).add(Number(estimatedTime.split(' ')[0]), 'm').format('YYYY-MM-DD HH:mm')

      // Garage to source time and rest

      let tpcDbStart = dayjs(mainTripStartTime).subtract(garageToSource.time + garageToSource.rest, 'm').format('YYYY-MM-DD HH:mm')
      let tpcDbEnd = dayjs(tpcDbStart).add(garageToSource.time, 'm').format('YYYY-MM-DD HH:mm')

      console.log(`TPC - DB Start :: ${tpcDbStart}`)
      console.log(`TPC - DB End :: ${tpcDbEnd}`)

      let trPreVStart = tpcDbEnd
      let trPreVEnd = mainTripStartTime

      console.log(`TR - Pre-V Start :: ${trPreVStart}`)
      console.log(`TR - Pre-V End :: ${trPreVEnd}`)

      let trPostVStart = mainTripEndTime
      let trPostVEnd = dayjs(mainTripEndTime).add(destinationToGarage.rest, 'm').format('YYYY-MM-DD HH:mm')

      console.log(`Start :: ${mainTripStartTime}`)
      console.log(`End :: ${mainTripEndTime}`)

      console.log(`TR - POST-V Start :: ${trPostVStart}`)
      console.log(`TR - POST-V End :: ${trPostVEnd}`)

      let treVbStart = trPostVEnd
      let treVbEnd = dayjs(trPostVEnd).add(destinationToGarage.time, 'm').format('YYYY-MM-DD HH:mm')

      console.log(`TRe - VB Start :: ${treVbStart}`)
      console.log(`TRe - VB End :: ${treVbEnd}`)

      let itemsToPush = [
        {
          name: 'TPC - DB',
          start: tpcDbStart,
          end: tpcDbEnd,
          color: 'pink lighten-3'
        },
        {
          name: 'TR-Pre-V',
          start: trPreVStart,
          end: trPreVEnd,
          color: 'yellow lighten-1'
        },
        {
          name: 'Meeting',
          start: mainTripStartTime,
          end: mainTripEndTime,
          color: 'green accent-3'
        },
        {
          name: 'TR-Post. V',
          start: trPostVStart,
          end: trPostVEnd,
          color: 'yellow lighten-1'
        },
        {
          name: 'TRe - VB',
          start: treVbStart,
          end: treVbEnd,
          color: 'blue-grey lighten-4'
        }
      ]


      this.events.push(...itemsToPush)

    },

    viewFare() {
      let seat = `0.${this.parameters.seatPercent}`
      let luggage = `0.${this.parameters.luggagePercent}`
      if(Number(this.seat) < 5) {
        seat = 0
      }

      if(Number(this.luggage) < 7) {
        luggage = 0
      }

      let calculateDistance = Number(this.distance) * Number(this.parameters.pricePerKm)
      let travelPrice = Number(this.parameters.basicFlatRate) + calculateDistance
      let totalPrice = travelPrice * (1 + Number(seat) + Number(luggage))

      const finalFare = Math.round((totalPrice + Number.EPSILON) * 100) / 100

      console.log(finalFare)
    }
  }

}
</script>

<style>

</style>